<template>
  <div v-if="!showEditNewSchoolAdminLicence">

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <b-col 
            cols="12"
            md="6"
          >

            <!-- Licencia Administrativa -->
            <validation-provider
              #default="validationContext"
              :name="$t('models.admin_licence')"
              rules="required|max:100"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label="$t('models.admin_licence')"
                    label-for="admin_email"
                  >
                    <b-form-input
                      id="admin_email"
                      v-model="new_admin_email"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
<!-- {{getLoggedUserRole == 'admin' || getLoggedUserRole == 'support }} -->
                  <b-button
                    block
                    variant="flat-danger"
                    @click="showEditNewSchoolAdminLicence = true"
                  >
                    {{ $t('forms.assign_new_admin_licence')}}
                  </b-button>
                </b-col>
                
              </b-row>
            </validation-provider>

          </b-col>
          <b-col 
            cols="12"
            md="6"
          >
            <!-- MAX CHILDREN -->
            <validation-provider
              #default="validationContext"
              :name="$t('max_children')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label="$t('max_children')"
                    label-for="max_children"
                  >
                    <b-form-input
                      id="max_children"
                      v-model="new_max_children"
                      :state="getValidationState(validationContext)"
                      type="number"
                      min="0"
                      trim
                      :disabled="resolveMaxChildrenByRole"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
               
              </b-row>
            </validation-provider>

            <!-- VALID_UNTIL -->
            <validation-provider
              v-if="recordData.admin"
              #default="validationContext"
              :name="$t('fields.valid_until')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  :label="$t('fields.valid_until')"
                  label-for="valid_until"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    id="valid_until"
                    v-model="new_valid_until"
                    start-weekday="1"
                    class="mb-1"
                    :min="minDate"
                    :state="validateValidUntil"
                    :locale="getLocal"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-row>
            </validation-provider>
            <!-- New Password -->
            <validation-provider
              v-if="recordData.admin"
              #default="validationContext"
              :name="$t('fields.new_pass')"
              rules="password|max:30"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                  <b-form-group>
                    <label for="password">{{ $t('fields.new_pass')}}</label>
                    <b-input-group>
                      <b-form-input
                        id="password"
                        :type="passwordFieldType"
                        v-model="new_pass"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div class="invalid-feedback mb-1" 
                    :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>

                    <b-button
                      :disabled="new_pass && !validationContext.errors[0] ? false :true"
                      :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                      variant="primary"
                      class="mb-1 mr-0 mr-sm-1"
                      type="button"
                      @click="submitChanges"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    >
                      {{$t('settings.change_pass')}}
                    </b-button>
                  </b-card>
                </b-col>
              </b-row>
            </validation-provider>
          </b-col>
        </b-row>  


    <!-- Action Buttons -->
    <b-row>
      <b-col cols="12">
        <b-button
          :disabled="(new_max_children && new_admin_email && new_valid_until) ? false :true"
          variant="primary"
          class="mb-1 mr-0 mr-sm-1 d-inline-block"
          type="button"
          @click="submitAdminLicence"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{$t('forms.update')}}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          class="mb-1 mr-0 mr-sm-1 d-inline-block"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ name: 'school-view', params: { id: recordData.id } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>
      </b-col>
    </b-row>
      </b-form>

    </validation-observer>

  </div>
  <div v-else>
    <b-row>
      <b-col cols="12">
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ $t('Warning')}}
          </h4>
          <div class="alert-body">
            {{$t('message.change_tic_warning_message')}}
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12" md="6">
        <!-- Licencia Administrativa -->
        <validation-provider
          #default="validationContext"
          :name="$t('models.admin_licence')"
          rules="required|max:100"
          :custom-messages="{ required: $t('validators.required') }"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('models.admin_licence_actual')"
                label-for="admin_email"
              >
                <b-form-input
                :disabled="true"
                  id="admin_email"
                  v-model="new_admin_email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-provider>

        <!--NUEVA Licencia Administrativa -->
        <validation-provider
          #default="validationContext"
          :name="$t('models.new_admin_licence')"
          rules="required|max:100"
          :custom-messages="{ required: $t('validators.required') }"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('models.new_admin_licence')"
                label-for="new_admin_licence"
              >
                <b-form-input
                  id="new_admin_licence"
                  v-model="new_new_admin_licence"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-provider>
        <!-- ACTION BUTTONS -->
        <b-button
          :disabled="(new_new_admin_licence) ? false :true"
          variant="primary"
          class="mb-1 mr-0 mr-sm-1 d-inline-block"
          type="button"
          @click="submitNewSchoolAdminLicence"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{$t('forms.update')}}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          class="mb-1 mr-0 mr-sm-1 d-inline-block"
          @click="showEditNewSchoolAdminLicence = false"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.cancel') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="6">
        <!-- v-if="errors.length > 0" -->
        <b-alert 
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ $t('bulk.table.error')}}
          </h4>
          <div class="alert-body">
            <b-list-group >
              <b-list-group-item 
                v-for="e, i in errors"
                :key="i"
                variant="danger">{{$t('message.'+e.reason)}}</b-list-group-item>
            </b-list-group>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { editSchool, editChangeTIC} from '@/api/routes'

import {
  BCard,  BInputGroup, BFormDatepicker, BInputGroupAppend, BButton, BRow, BCol, BFormGroup, BFormInvalidFeedback, 
  BFormInput, BForm, 
    BAlert,

  BListGroup, BListGroupItem
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BInputGroup, BInputGroupAppend,
    BListGroup, BListGroupItem,
    BAlert,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
    valid_until: {
      type: String,
      required: false
    }
  },
  mixins: [togglePasswordVisibility],
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
    extend('password', {
      ...password,
      message: this.$t('forms.errors.password'),
    });
  },
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      
      required,
      alphaNum,
      password,

      minDate: tomorrow,
      
      new_valid_until:null,
      new_pass:'',
      new_admin_email:'',
      new_max_children:0,
      new_new_admin_licence:'',

      showEditNewSchoolAdminLicence: false,

      errors:[]
    }
  },
  computed: {
    resolveMaxChildrenByRole(){
      /* Devuelve si el rol de la persona logueada tiene la capacidad de cambiar el max_children */
      let loggedUser = decryptData(localStorage.getItem('userData'))
      if(loggedUser.role == "admin" || loggedUser.role == "partner" || loggedUser.role == "support"){
        return false
      } else {
        return true
      }
    },
    getLoggedUserRole(){
      let loggedUser = decryptData(localStorage.getItem('userData'))
      return loggedUser.role
    },
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    validateValidUntil(){
      return this.new_valid_until ? true : false
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted(){
    if(this.recordData && this.recordData.user_admin_id){
      this.new_admin_email = this.recordData.admin.email
      this.new_valid_until = this.valid_until
      this.new_max_children = this.recordData.admin.max_children
    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
    async submitNewSchoolAdminLicence () {
      this.errors = []
      let data = {
        "email_source" : this.new_admin_email,
        "email_target" : this.new_new_admin_licence,
        "school_id" : this.recordData.id
      }
        // console.log(data)

     await this.$http.post(editChangeTIC, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.showEditNewSchoolAdminLicence = false
          this.$emit('refresh-data', response.data.school)
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.errors = response.data.details
          //this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async submitAdminLicence(){
      let data = {
        tab: 'admin_licence',
        id: this.recordData.id,
        admin_email: this.new_admin_email,
        valid_until: this.new_valid_until,
        max_children: Math.abs(Math.round(this.new_max_children))
      }
      await this.$http.post(editSchool, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.$emit('refresh-data', response.data.school)
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async submitChanges(){
      // console.log(this.recordData)
      let data = {
        tab: 'admin_licence_password',
        id: this.recordData.id,
        new_pass: this.new_pass,
      }
      await this.$http.post(editSchool, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
          this.new_pass = '';
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
</style>
